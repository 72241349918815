import React, { useState, useEffect, useContext } from "react"
import { useQuery } from "@apollo/react-hooks"
import classNames from "classnames"
import { gql } from "apollo-boost"
import moment from "moment"

import {
  hasSeenNotification,
  setNotificationAsSeen,
  createNotificationStructuredQuery,
} from "./services/notification"
import styles from "./utils/layout.module.scss"
import { isBrowser } from "../../services/general"
import { AppContext } from "../../context/AppContext"
import { GATSBY_CLIENT_EMAIL } from "gatsby-env-variables"
import { queryDocuments } from "../../services/firebase/firebaseApi"

const Notification = () => {
  const { state, dispatch } = useContext(AppContext)

  const [bannerData, setBannerData] = useState("")
  const [notificationHidden, setNotificationHidden] = useState(false)

  let dateToday = moment().format("YYYY-MM-DD")
  const hasSeenNotificationMessage = hasSeenNotification()

  useEffect(() => {
    const initializeBanner = async () => {
      let request = await queryDocuments({
        structuredQuery: createNotificationStructuredQuery({
          collectionId: "announcements",
        }),
      })

      let requestData = request?.data
      let filteredData = []
      for (let counter = 0; counter < requestData?.length; counter++) {
        let startDate = moment(
          requestData[counter]?.document?.fields?.startDate?.timestampValue
        ).format("YYYY-MM-DD, h:mm:ss")

        let endDate = moment(
          requestData[counter]?.document?.fields?.endDate?.timestampValue
        ).format("YYYY-MM-DD, h:mm:ss")

        let isBetween = moment(dateToday).isBetween(startDate, endDate)

        if (isBetween) {
          filteredData.push(requestData[counter])
        }
      }

      let bannerMessage =
        filteredData[0]?.document?.fields?.message?.stringValue
      setBannerData(bannerMessage)
    }
    initializeBanner()
  }, [])

  // const { loading, error, data } = useQuery(
  //   gql`
  //     query TodaysDate($holiday_date: date!, $domain: jsonb) {
  //       holiday(
  //         where: {
  //           holiday_date: { _eq: $holiday_date }
  //           domains: { _contains: $domain }
  //         }
  //       ) {
  //         name
  //         holiday_date
  //         short_message
  //       }
  //     }
  //   `,
  //   {
  //     variables: {
  //       holiday_date: dateToday,
  //       domain: process.env.GATSBY_AWS_S3_BUCKET_NAME,
  //     },
  //   }
  // )

  let notificationMessage = bannerData

  let notificationEmail = GATSBY_CLIENT_EMAIL
  useEffect(() => {
    if (isBrowser()) {
      const urlSearchParams = new URLSearchParams(window?.location?.search)

      if (!state?.client && window?.location?.pathname?.includes("medensure")) {
        dispatch({
          type: "SAVE_CLIENT",
          payload: urlSearchParams.get("client") || "",
        })
      }
    }
  }, [])

  let company = ""
  if (isBrowser()) company = sessionStorage.getItem("company")

  if (state?.client || company) {
    switch (state?.client || company) {
      case "Accenture":
        notificationEmail = "accenture.med@medgrocer.com"
        break
      case "Maxicare":
        notificationEmail = "maxicare.med@medgrocer.com"
        break
      case "Foundever":
        notificationEmail = "foundever.med@medgrocer.com"
        break
      default:
        notificationEmail = "order@medgrocer.com"
        break
    }
  }

  if (!hasSeenNotificationMessage)
    return (
      <div
        className={classNames(
          "notification is-warning",
          {
            "is-hidden": notificationHidden,
          },
          styles["notification"]
        )}
      >
        <button
          className="delete"
          onClick={() => {
            setNotificationHidden(true)
            setNotificationAsSeen()
          }}
        ></button>
        <p className="is-size-6 has-text-centered">
          Due to telco issues arising from SIM registration, we will be sending
          you updates via email.
        </p>
        <p className={classNames("is-size-6 has-text-centered")}>
          For any inquiries, please email us at{" "}
          <a href={`mailto:${notificationEmail}`}>{notificationEmail}</a>.
        </p>
        <div
          className="has-text-centered"
          dangerouslySetInnerHTML={{
            __html: notificationMessage?.replaceAll(
              "<p>",
              "<p class='is-size-6'>"
            ),
          }}
        />
      </div>
    )

  return null
}

export default Notification
